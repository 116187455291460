import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "jobAssignments";

export default {
  getAll() {
    const url = `${baseUrl}/${endpoint}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByTimecardPeriod(startDate: string, endDate: string) {
    const url = `${baseUrl}/${endpoint}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getRecruiterAssignments(recruiterId: string) {
    const url = `${baseUrl}/${endpoint}/recruiter/${recruiterId}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByTeamLeadTimecardPeriod(teamLeadId: string | undefined, startDate: string, endDate: string) {
    const url = `${baseUrl}/${endpoint}/teamlead/${teamLeadId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByAssistantTimecardPeriod(startDate: string, endDate: string) {
    const url = `${baseUrl}/${endpoint}/assistant?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByRecruiterTimecardPeriod(recruiterId: string, startDate: string, endDate: string) {
    const url = `${baseUrl}/${endpoint}/recruiter/${recruiterId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getHistoryByRecruiterTimecardPeriod(
    recruiterId: string | undefined,
    startDate: string,
    endDate: string
  ) {
    const url = `${baseUrl}/${endpoint}/recruiter-history/${recruiterId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getBySalesRepTimecardPeriod(salesRepId: string, startDate: string, endDate: string) {
    const url = `${baseUrl}/${endpoint}/salesrep/${salesRepId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getHistoryBySalesRepTimecardPeriod(
    recruiterId: string | undefined,
    startDate: string,
    endDate: string
  ) {
    const url = `${baseUrl}/${endpoint}/salesrep-history/${recruiterId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  saveNote(jobId: string, messageBody: string) {
    const url = `${baseUrl}/jobassignment/${jobId}/notes/save`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: messageBody,
    });
  },
  //Api to upload confirmation page
  fileUpload(type: string, jobId: string, files: FileList) {
    const extension = files[0].name.split(".").pop();
    const url = `${baseUrl}/${endpoint}/${type}/${jobId}.${extension}`;
    return msalApiFetch(url, {
      method: "POST",
      body: files[0],
    });
  },
  getDealSheetTilesForCandidate(candidateId: string) {
    const url = `${baseUrl}/dealSheets/${candidateId}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getDealSheetTilesForCandidateAndRecruiter(candidateId: string, recruiterId: string) {
    const url = `${baseUrl}/recruiter/${recruiterId}/dealSheets/${candidateId}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getByVmsTimecardPeriod(vmsId: string, startDate: string, endDate: string) {
    const url = `${baseUrl}/timesheets/vms/${vmsId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getDealsheetsTilesByCandidateName(candidateName: string, maxResults: number) {
    const url = `${baseUrl}/dealSheets?filter=${candidateName}&maxResults=${maxResults}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
  async getJobAssignmentsForwardingHistory(timesheetId: string) {
    const url = `${baseUrl}/${endpoint}/${timesheetId}/ForwardingHistory`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
  getJobAssignmentsByRecruiter(recruiterId: string, filter: string) {
    return msalApiFetch(`${baseUrl}/recruiter/${recruiterId}/assignments?filter=${filter}`).then(
      (data) => {
        if (!data.ok) throw data;
        return data.json();
      }
    );
  },
  getJobAssignmentById(jobId: string) {
    const url = `${baseUrl}/jobassignment/${jobId}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
  updateAdpCode(jobId: string, adpCode: string) {
    const url = `${baseUrl}/jobassignment/${jobId}/updateAdpCode`;

    return msalApiFetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ adpCode }),
    });
  },
  getMaxDate() {
    const url = `${baseUrl}/advanced-search/timesheets/maxEndDate`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
};
